import React from 'react';

const InputForm = ({ matricula, setMatricula, placa, setPlaca }) => {
  return (
    <div style={styles.inputContainer}>
      <input
        type="text"
        value={matricula}
        onChange={(e) => setMatricula(e.target.value)}
        placeholder="Digite sua matrícula"
        style={styles.input}
      />
      <input
        type="text"
        value={placa}
        onChange={(e) => setPlaca(e.target.value.toUpperCase())}
        placeholder="Digite a placa do veículos"
        style={styles.input}
      />
    </div>
  );
};

const styles = {
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    width: '40%',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
};

export default InputForm;
