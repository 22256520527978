import React, { useState, useEffect } from 'react';
import InputForm from './components/InputForm';
import WebcamCapture from './components/WebcamCapture';
import Dashboard from './components/Dashboard'; // Importando o Dashboard

function App() {
  const [matricula, setMatricula] = useState('');
  const [placa, setPlaca] = useState('');
  const [captura, setCaptura] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [offlineQueue, setOfflineQueue] = useState([]);

  // Listar câmeras disponíveis
  const listarCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setCameras(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedCamera(videoDevices[videoDevices.length - 1].deviceId);
      }
    } catch (err) {
      console.error("Erro ao listar as câmeras: ", err);
    }
  };

  useEffect(() => {
    listarCameras();
  }, []);

  // Função para registrar acesso
  const registrarAcesso = () => {
    if (!matricula || !placa || !captura) {
      alert('Por favor, insira a matrícula, a placa e capture uma foto!');
      return;
    }

    const offlineData = { matricula, placa, captura };
    setOfflineQueue([...offlineQueue, offlineData]);
    localStorage.setItem('offlineQueue', JSON.stringify([...offlineQueue, offlineData]));
    alert("Sem conexão à internet. Registro salvo e será enviado quando a conexão for restabelecida.");
  };

  return (
    <div className="App" style={styles.container}>
      <InputForm matricula={matricula} setMatricula={setMatricula} placa={placa} setPlaca={setPlaca} />
      <WebcamCapture cameras={cameras} selectedCamera={selectedCamera} setSelectedCamera={setSelectedCamera} captura={captura} setCaptura={setCaptura} />
      <button onClick={registrarAcesso} style={styles.button}>Registrar Acesso</button>

      {/* Exibindo o Dashboard */}
      <Dashboard />

      {offlineQueue.length > 0 && <p style={styles.offlineMessage}>Você está offline. O acesso será registrado quando a conexão for restabelecida.</p>}
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '10px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#1a1a1a',
    minHeight: '100vh',
    color: '#fff',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#ff4500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
  },
  offlineMessage: {
    fontSize: '16px',
    color: '#D9534F',
    marginTop: '20px',
  },
};

export default App;
