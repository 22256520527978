import React, { useState, useEffect } from 'react'; import { Bar, Pie 
} from 'react-chartjs-2';
const Dashboard = () => { const [chartData, setChartData] = 
    useState({
        acessosDiarios: [], dispositivosName: [], dispositivosData: 
        [], heatmapData: [], labelsAcessosDiarios: [],
    });
    const fetchChartData = async (days = 1) => { try { const response 
            = await fetch(`/api/get_chart_data/?days=${days}`); const 
            data = await response.json(); setChartData({
                acessosDiarios: data.acessos_diarios, 
                dispositivosName: data.dispositivos_name, 
                dispositivosData: data.dispositivos_id, heatmapData: 
                data.heatmap_data, labelsAcessosDiarios: 
                data.labels_acessos_diarios,
            });
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
        }
    };
    useEffect(() => { fetchChartData();
    }, []);
    return ( <div className="container-fluid pt-4 px-4"> <div 
            className="row g-4">
                <div className="col-sm-6 col-xl-3"> <div 
                    className="bg-secondary rounded p-4">
                        <h3 className="text-white">Acessos 
                        Diários</h3> <Bar
                            data={{ labels: 
                                chartData.labelsAcessosDiarios, 
                                datasets: [{
                                    label: 'Acessos Diários Únicos', 
                                    data: chartData.acessosDiarios, 
                                    backgroundColor: '#FF4500',
                                }],
                            }}
                            options={{ scales: { x: { grid: { color: 
                                    '#404040' } }, y: { grid: { 
                                    color: '#404040' } },
                                },
                            }}
                        /> </div> </div> <div className="col-sm-6 
                col-xl-3">
                    <div className="bg-secondary rounded p-4"> <h3 
                        className="text-white">Acessos por 
                        Dispositivo</h3> <Pie
                            data={{ labels: 
                                chartData.dispositivosName, datasets: 
                                [{
                                    data: chartData.dispositivosData, 
                                    backgroundColor: ['#FF4500', 
                                    '#FF6347', '#FF7F50'],
                                }],
                            }}
                            options={{ plugins: { legend: { position: 
                                    'right' },
                                },
                            }}
                        /> </div> </div> </div> </div> );
};
export default Dashboard;
