import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';

const WebcamCapture = ({ cameras, selectedCamera, setSelectedCamera, captura, setCaptura }) => {
  const webcamRef = useRef(null);

  // Alternar entre as câmeras
  const alternarCamera = () => {
    if (cameras.length > 1) {
      const currentIndex = cameras.findIndex(camera => camera.deviceId === selectedCamera);
      const nextIndex = (currentIndex + 1) % cameras.length;
      setSelectedCamera(cameras[nextIndex].deviceId);
    }
  };

  // Capturar a foto da webcam
  const capturarFoto = () => {
    if (!webcamRef.current) return; // Verifica se a webcam está disponível
    const imagemCapturada = webcamRef.current.getScreenshot();
    if (!imagemCapturada) {
      alert("Erro ao capturar a imagem. Verifique se a câmera está funcionando corretamente.");
      return;
    }
    setCaptura(imagemCapturada);
  };

  return (
    <div>
      <button onClick={alternarCamera} style={styles.switchButton}>Alternar Câmera</button>

      {cameras.length > 0 && captura === null ? (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{ deviceId: selectedCamera }}
          style={styles.ampliada}
        />
      ) : (
        captura && <img src={captura} alt="Captura" style={styles.ampliada} />
      )}

      <div style={styles.buttonContainer}>
        {captura ? (
          <button onClick={() => setCaptura(null)} style={styles.button}>Capturar Novamente</button>
        ) : (
          <button onClick={capturarFoto} style={styles.button}>Capturar Foto</button>
        )}
      </div>
    </div>
  );
};

const styles = {
  ampliada: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    border: '2px solid #ff4500',
  },
  switchButton: {
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#ff4500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    backgroundColor: '#ff4500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
};

export default WebcamCapture;